<template>
    <b-card>
        <b-card-header class="p-0 pb-3">
            <h4>
                MyGroundBiz
                <!-- <b-form-checkbox
                    checked="true"
                    name="check-button"
                    switch
                    inline
                /> -->
            </h4>
        </b-card-header>
        <b-card-body class="p-0">
            <b-row name="first">
                <b-col cols="8">
                    <b-row>
                        <b-col cols="12">
                            <b-form-group
                                label="Username"
                                label-for="username"
                                label-cols-md="4"
                            >
                                <b-form-input
                                    v-model="myGroundBizUsername"
                                    id="username"
                                    placeholder="Username"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                                label="Password"
                                label-for="password"
                                label-cols-md="4"
                            >
                                <b-form-input
                                    type="password"
                                    v-model="myGroundBizPassword"
                                    id="password"
                                    placeholder="Password"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="4">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="btnConnect"
                        @click="saveCredentials"
                        >Save
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="info"
                        class="btnConnect"
                        @click="testCredentials"
                        style="margin-right: 10px;"
                        >Test
                    </b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="3"
                    ><div>{{ statusMessage }}</div></b-col
                >
            </b-row>
            <b-row class="mt-2">
                <b-col cols="12">
                    <h5>Recent Updates</h5>
                    <b-row class="recent" style="font-size: 14px;font-weight: bold;margin-left: 31px;">
                        <div>Type</div>
                        <div>Date</div>
                        <div>Status</div>
                    </b-row>
                    <b-row
                        v-for="update in recentUpdates"
                        :key="update.id"
                        class="recent"
                    >
                        <div>{{ update.type }}</div>
                        <div>
                            {{ getFormattedDate(update.created_at) }}
                        </div>
                        <div>{{ update.status }}</div>
                    </b-row>
                </b-col>
                <!-- <b-col cols="4">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="$refs.refInputEl.$el.click()" class="btnConnect mt-auto">
                    Manual CSV Upload
                </b-button>
                <b-form-file ref="refInputEl" accept=".csv" :hidden="true" plain />
            </b-col> -->
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormCheckbox,
    BFormFile,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
} from "bootstrap-vue";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import { serverUri } from "@/config";
import { getUserData } from "@/auth/utils";
import store from "@/store";
import axios from "axios";

export default {
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BButton,
        BFormCheckbox,
        BFormFile,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            statusMessage: "",
            myGroundBizUsername: "",
            myGroundBizPassword: "",
            recentUpdates: [],
        };
    },
    methods: {
        saveCredentials() {
            axios
                .post(`${serverUri}/api/company/apiSave`, {
                    companyId: getUserData().company_id,
                    type: "mygroundbiz",
                    apikey: this.myGroundBizUsername,
                    secret: this.myGroundBizPassword,
                    mode: "Weekly settlement",
                })
                .then((response) => {
                    if (response.data) {
                        setTimeout(() => {
                            this.fetchConnections();
                            this.statusMessage = "Credentials updated.";
                            this.myGroundBizPassword = "";
                            this.myGroundBizUsername = "";
                        }, 2000);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        testCredentials() {
            axios
                .post(`${serverUri}/api/company/apiSave`, {
                    companyId: getUserData().company_id,
                    type: "mygroundbiz",
                    apikey: this.myGroundBizUsername,
                    secret: this.myGroundBizPassword,
                    mode: "Test settlement",
                })
                .then((response) => {
                    if (response.data) {
                        setTimeout(() => {
                            this.fetchConnections();
                            this.statusMessage = "Credentials updated.";
                            this.myGroundBizPassword = "";
                            this.myGroundBizUsername = "";
                        }, 2000);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        getFormattedDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },
        fetchConnections() {
            store
                .dispatch(
                    "appConnection/fetchConnections",
                    getUserData().company_id
                )
                .then((response) => {
                    this.recentUpdates = store.state.appConnection.connections;
                });
        },
    },

    created() {
        this.fetchConnections();
    },
};
</script>

<style scoped>
.btnConnect {
    float: right;
}

.recent {
    display: flex;
    justify-content: space-around;
}
</style>
